
  import { defineComponent, onBeforeMount, reactive, toRefs, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { getAllUsers, enableUser, disableUser } from '@/api/userinfo';
  import { ElMessageBox, ElMessage } from 'element-plus';
  import { Download } from '@element-plus/icons';
  import httpRequest, { getToken } from '@/utils/axios/axiosUtils';
  import downLoad from '../../utils/download';

  export default defineComponent({
    name: 'userManage',
    setup() {
      const router = useRouter();
      const state = reactive({
        searchForm: {
          userName: '',
          enable: '',
        },
        addForm: null,
        tableData: [],
        pageNumber: 1,
        pageSize: 12,
        totalCount: 0,
        organizationData: [],
      });

      const getData = async (params: any) => {
        const res: any = await getAllUsers(params);
        if (res.code === 0) {
          state.tableData = res.data.content;
          state.totalCount = res.data.total;
        }
      };

      const exportExcel = async () => {
        const params = {
          pageNumber: state.pageNumber - 1,
          pageSize: state.pageSize,
        };
        Object.keys(state.searchForm).forEach((key) => {
          if (state.searchForm[key]) {
            params[key] = state.searchForm[key];
          }
        });
        const fileData = await httpRequest.post('/user/export', params, {
          responseType: 'blob',
        });
        downLoad(fileData);
      };

      const updateData = () => {
        const params = {
          pageNumber: state.pageNumber - 1,
          pageSize: state.pageSize,
        };
        Object.keys(state.searchForm).forEach((key) => {
          if (state.searchForm[key]) {
            params[key] = state.searchForm[key];
          }
        });
        getData(params);
      };
      const handleAdd = () => {
        router.push('/addUser');
      };
      const handleEdit = (row: any) => {
        router.push({ path: '/editUser', query: { id: row.userId } });
      };

      // 关联系统
      const handleRelation = (row: any) => {
        router.push({ path: '/relationSystems', query: { id: row.userId } });
        console.log(row);
      };
      const handleEnable = async (value: any, row: any) => {
        const res: any = await (value ? enableUser(row.userId) : disableUser(row.userId));
        if (res.code === 0) {
          console.log(res);
          ElMessage({
            type: 'success',
            message: `您已${value ? '启用' : '禁用'}成功`,
          });
          updateData();
        }
      };
      const handleReset = () => {
        const form: any = state.addForm;
        form.resetFields();
      };

      onBeforeMount(() => {
        const params = {
          pageNumber: state.pageNumber - 1,
          pageSize: state.pageSize,
        };
        getData(params);
      });
      return {
        ...toRefs(state),
        updateData,
        Download,
        exportExcel,
        getData,
        handleAdd,
        handleEdit,
        handleEnable,
        handleReset,
        handleRelation,
      };
    },
  });
