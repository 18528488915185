import FileSaver from 'file-saver';

export default function downLoad(res: any) {
  const fileName: any = window.decodeURI(res.headers['content-disposition']?.split('=')[1]);
  const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
  FileSaver.saveAs(blob, fileName);
}
// export function downLoad(fileData: any) {
//   const fileName: any = window.decodeURI(fileData.headers['content-disposition'].split('=')[1]);
//   const blob = new Blob([fileData.data], { type: 'application/vnd.ms-excel' });
//   const link = document.createElement('a');
//   link.style.display = 'none';
//   link.href = URL.createObjectURL(blob);
//   link.setAttribute('download', fileName);
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// }
